<template>
    <tr class="odd" v-bind:class="{'d-none': !(depth == 0 || showRow) }">
        <td class="text-center text-nowrap">
            <span class="custom-table-row-indent" v-if="depth != 0" v-bind:class="['indent-level-' + (depth)]" v-bind:style="{'padding-left': (depth * 20)+ 'px'}"></span>
            <span v-if="expandableRows" class="expandColumn cursor-pointer collapsible rotate" v-bind:class="{collapsed: !openedExpandRow(rowIndex)}" v-on:click="handleExpandRow(row, rowIndex)">
                <div class="me-3 rotate-90" v-if="showExpandArrow">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/arrows/arr071.svg" />
                    </span>
                </div>
                <span v-else-if="depth != 0" class="none-expanded-icon"></span>
            </span>
            <span class="form-check-table form-check form-check-sm form-check-custom form-check-solid" v-if="selectableRows" v-bind:class="{'ms-3': expandableRows && showExpandArrow }">
                <input class="form-check-input selectableRows" type="checkbox" :value="rowKey ? row[rowKey] : row" v-model="selectRows.checkedRows" @change="changeRowSelect($event, 'single')"/>
            </span>
        </td>
        <template v-for="(cell, i) in columns" :key="i">
            <td :class="[{ 'text-end': columns.length - 1 === i }, cell.rowClass]" :style="cell.rowStyle">
                <slot v-if="cell.scopedSlots" :name="cell.scopedSlots.customRender" :row="row" :rowIndex="rowIndex">
                    {{ $slots[cell.key] ? prop : objectPath.get(row, cell.key) }}
                </slot>
                <template v-else>{{ objectPath.get(row, cell.key) }}</template>
            </td>
        </template>
        <!--end::Item=-->
    </tr>
    <TreeRow v-for="(child, childIndex) in row.children"
             :key="depth + '_' + childIndex"
             :rowIndex="childIndex"
             :row="child"
             v-bind="childProps"
             :showRow="openedExpandRow(rowIndex) && showRow"
             :depth="depth + 1">
        <!-- pass through normal slots -->
        <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
            <slot :name="name" v-bind="slotData" />
        </template>
    </TreeRow>
    <template v-if="$slots.expandedRowRender">
        <tr class="odd expandRow" :class="{'d-none': !openedExpandRow(rowIndex) }">
            <td></td>
            <td :colspan="columns.length">
                <slot name="expandedRowRender" :row="row" :rowIndex="rowIndex">
                    {{ prop }}
                </slot>
            </td>
        </tr>
    </template>
</template>

<script>
import TreeRow from '@/components/custom-table/treeRow.vue';

export default {
    name: "tree-row",
    props: {
        columns: {type: Array, required: true},
        selectableRows: {type: Boolean, default: false},
        expandableRows: {type: Boolean, default: false},
        expandableRowConfig: {type: Object, default: () => { return {}}},
        showRow: {type: Boolean, default: false},
        rowKey: {type: String, default: undefined},
        rowIndex: {type: Number, required: true},
        row: {type: Object, required: true},
        slots: {type: Object, default: () => {}},
        depth: {type: Number, default: 0},
    },
    components: {
        TreeRow
    },
    inject: ['changeRowSelect', 'selectRows', 'changedExpandRow'],
    data() {
        return {
            expandRow: {
                opened: []
            }
        }
    },
    computed:{
        childProps() {
            let props = JSON.parse(JSON.stringify(this.$props));

            delete props.row;
            delete props.rowIndex

            return props;
        },
        showExpandArrow(){
            return this.$slots.expandedRowRender || (this.expandableRowConfig.notCheckChildrenLength ? this.row.children : this.row.children.length);
        }
    },
    methods: {
        openedExpandRow(index) {
            let status = this.expandRow.opened.includes(index);

            if(this.expandableRowConfig.notCheckChildrenLength && status && this.row.children && !(this.row.children.length > 0)){
                status = false;
            }

            return status;
        },
        handleExpandRow(record, recordIndex) {
            let x = this.expandRow.opened.indexOf(recordIndex);
            let status = x === -1;

            if(status) {
                this.expandRow.opened.push(recordIndex);
            } else {
                if(this.expandableRowConfig.notCheckChildrenLength && this.row.children && !(this.row.children.length > 0)) {
                    status = true;
                } else {
                    this.expandRow.opened.splice(x, 1);
                }
            }

            this.changedExpandRow(record, status)
        }
    }
}
</script>

<style>
.form-check-table {
    display:  inline-block !important;
}
.none-expanded-icon{
    width: 1.35rem;
}
</style>