<template>
    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{ $t('filters.title') }}</div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5 overflow-auto max-h-300px">
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.video.title') }}:</label>
                <div>
                    <el-input v-model="filterData.title" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.video.extension') }}:</label>
                <div>
                    <el-select v-model="filterData.extensions" class="w-100" :placeholder="$t('common.chooseSelect')" clearable multiple>
                        <el-option v-for="(extension, extensionIndex) in extensions" :key="extensionIndex" :value="extension"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="">
                <label class="form-label fw-bold">{{ $t('filters.video.videoGroup') }}:</label>
                <div>
                    <el-select v-model="filterData.group_ids" :placeholder="$t('common.chooseSelect')" class="w-100" clearable filterable multiple>
                        <el-option v-for="(group, groupIndex) in videoGroups" :key="groupIndex" :value="group.id" :label="group.name"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="separator border-gray-200"></div>
        <div class="px-7 py-5">
            <div class="d-flex justify-content-end">
                <button type="reset" v-on:click="onReset" class="btn btn-sm btn-white btn-active-light-primary me-2" data-kt-menu-dismiss="true">{{ $t('btn.reset') }}</button>
                <button type="submit" v-on:click="onFilter" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">{{ $t('btn.apply') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data(){
        return {
            filterData: {},
            extensions: ['mp4']
        }
    },
    computed: {
        videoGroups() {
            return this.$store.state.video.group.table.data;
        },
    },
    mounted() {
        this.$store.dispatch('video/group/get', {
            page: { pageSize: 9999 }
        });

        this.eventBus.$on('searchEvent', (data) => {
            this.filterData.title = data;
            this.onFilter();
        });
    },
    methods: {
        onReset(){
            this.filterData = {};
            this.onFilter();
        },
        onFilter(){
            this.$emit('onFilter', this.filterData);
        }
    },
    beforeUnmount() {
        this.eventBus.$off('searchEvent', undefined, true);
    },
}
</script>

<style>

</style>